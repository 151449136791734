<template>
  <div class="head-container performance-header">
    <!-- 搜索 -->
    <el-input
      v-model="query.managerInfo"
      clearable
      placeholder="请输入销售姓名/ID"
      style="width: 400px;"
      class="input-with-select"
      @keyup.enter.native="toQuery">
      <el-select
        style="width: 180px;"
        size="small"
        slot="append"
        class=""
        v-model="query.dataType"
        clearable
        placeholder="请选择"
      >
        <el-option
          v-for="(item,index) in typeList"
          :key="index"
          :value="item.value"
          :label="item.label"
        ></el-option>
      </el-select>
    </el-input>
      <el-select
              @change="toQuery"
              v-model="query.position"
              clearable
              placeholder="职位"
              class="filter-item"
              style="width: 130px"
      >
          <el-option
                  v-for="item in positionList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
          />
      </el-select>
    <el-select
      class="filter-item"
      v-model="empStatus"
      clearable
      placeholder="请选择状态"
      @change="toQuery"
    >
      <el-option
        v-for="(item,index) in statueList"
        :key="index"
        :value="item.value"
        :label="item.label"
      ></el-option>
    </el-select>
     <div class="filter-item">
       <!--接口必须传时间, 选择时间-->
       <span class="filter-item">平仓时间</span>
      <el-date-picker
        style="width: 150px;"
        v-model="startTime"
        type="date"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        placeholder="开始时间">
      </el-date-picker>
      至
      <el-date-picker
        style="width: 150px;"
        v-model="endTime"
        type="date"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        placeholder="结束时间">
      </el-date-picker>
    </div>


    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-close" @click="clear">清空</el-button>
    <el-button
            v-if="checkPermission([ 'EXPORT_MANAGER_STATISTICS','ADMIN'])"
      class="filter-item"
      size="mini"
      type="primary"
      @click="exportHandle"
      :loading="loading"
    >导出</el-button>
      <el-popover
              placement="top-start"
              width="100"
              trigger="hover"
      >
          <div class="done">
              <div class="all-performance">
                  <el-checkbox
                          :indeterminate="isIndeterminate"
                          v-model="checkAll"
                          @change="allChange"
                  >列展示</el-checkbox>
              </div>
              <!--              <el-button-->
              <!--                      @click="resetHandle"-->
              <!--                      type="text">重置</el-button>-->
          </div>
          <div class="col-checkbox-performance">
              <el-checkbox-group
                      v-model="checkListHeader"
                      @change="checkboxChange"
              >
                  <el-checkbox
                          v-for="(item,index) in colList"
                          :key="index"
                          :label="item.label"
                          @change=""
                  >{{item.text}}</el-checkbox>
              </el-checkbox-group>
          </div>
          <el-button class="filter-item" size="mini" type="primary"  slot="reference" icon="el-icon-setting">列展示</el-button>
      </el-popover>
  </div>
</template>

<script>
  import { init_date } from '@/utils/index'
import checkPermission from '@/utils/permission' // 权限判断函数
export default {
  props: {
    query: {
      type: Object,
      required: true
    },
    colList: {
      type: Array,
      default(){
        return [];
      }
    },
    checkList: {
      type: Array,
      default(){
        return [];
      }
    },
    allCheckList: {
      type: Array,
      default(){
        return [];
      }
    },
    positionList: {
      type: Array,
      default(){
        return []
      }
    }
  },
  data() {
    return {
      startTime: '',
      endTime: '',
      empStatus:1,
      statueList:[
        {
          value: 1,
          label: '在职',
        },
        {
          value: 2,
          label: '离职',
        }
      ],
      typeList: [
        {
          label: '本人',
          value: 1,
        },
        {
          label: '所有下级',
          value: 2,
        },
        {
          label: '本人 + 所有下级',
          value: 0,
        },
      ],
      loading: false,
      checkListHeader: this.checkList,
      isIndeterminate: true,
      checkAll: false,
      cahcheCheckList: [],
    }
  },
  created(){
    this.initDate();
  },
  watch: {
    checkList(newValue, oldValue) {
      this.checkListHeader = newValue;
    }
  },
  methods: {
    // resetHandle(){
    //
    // },
    allChange(flag){
      if(flag){
        this.checkListHeader = this.allCheckList;
      }else {
        this.checkListHeader = [];
      }
      this.$emit('send-checkedList',this.checkListHeader);
    },
    checkboxChange(checkedList){
      this.$emit('send-checkedList',checkedList);
    },
    initDate(){
      let dateInfo = init_date();
      this.endTime = dateInfo.today;
      this.startTime = dateInfo.firstday;
      this.empStatus = 1;
    },
    checkPermission,
    toQuery() {
      this.$parent.page = 0;
      this.$parent.init();
      this.$parent.getTotol();
    },
    clear() {
      this.empStatus = '';
      this.$emit('clear');
    },
    exportHandle(){
      this.$emit('export');
    },
  }
}
</script>
<style lang="less">
  .performance-header {
    .el-input-group__append {
      background-color: #ffffff;
    }
      .input-with-select {
          vertical-align: top;
          margin-right: 5px;
      }

  }
  .all-performance {
      border-bottom: 1px solid #f0f0f0;
      padding-bottom: 15px;
  }
    .col-checkbox-performance {
        padding: 20px 10px;
        .el-checkbox {
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
</style>
