<template>
  <div class="app-container performanceDetail deep">
    <eHeader
            ref='header'
            @clear="clearHandle"
            :query="query"
            @export="exportHandle"
            :position-list="positionList"
            :col-list="colList"
            :check-list="checkList"
            :all-check-list="allCheckList"
            @send-checkedList="sendCheckHandle"
    />
    <!--表格渲染-->
    <el-table
      v-loading="loading"
      :data="data"
      stripe
      show-summary
      :summary-method="getSummaries"
      size="small"
      style="width: 100%;"
    >
      <el-table-column
              v-if="getShow('managerId')"
              prop="managerId"
              label="销售ID"
      />
      <el-table-column
              v-if="getShow('managerName')"
              prop="managerName"
              label="销售姓名"
      />
      <el-table-column label="职位">
        <template slot-scope="scope">
          <span>{{getPosition(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column
              v-if="getShow('signDate')"
              prop="signDate"
              label="入职时间"
      />
      <el-table-column
              v-if="getShow('parentManagerId')"
              prop="parentManagerId"
              label="上级ID"
      />
      <el-table-column
              v-if="getShow('parentManagerName')"
              prop="parentManagerName"
              label="上级姓名"
      />
      <el-table-column
              v-if="getShow('personalCustomer')"
              prop="personalCustomer"
              label="客户数"
      />
      <el-table-column
              v-if="getShow('personalAgent')"
              prop="personalAgent"
              label="代理数"
      />
      <el-table-column
              v-if="getShow('equity')"
              prop="equity"
              label="总净值"
      />
      <el-table-column
              v-if="getShow('credit')"
              prop="credit"
              label="总信用额"
      />
      <el-table-column
              v-if="getShow('balance')"
              prop="balance"
              label="总余额"
      />
      <el-table-column
              v-if="getShow('personalProfit')"
              prop="volume"
              label="总盈亏"
      >
        <template slot-scope="scope">
          <div class="profit-text" :class="{on: getGreen(scope.row)}">{{scope.row.personalProfit}}</div>
        </template>
      </el-table-column>
      <el-table-column
              v-if="getShow('personalVolume')"
              prop="openPrice"
              label="交易手数"
      >
        <template slot-scope="scope">
          <div>{{scope.row.personalVolume}} 手 </div>
        </template>
      </el-table-column>
      <el-table-column
              v-if="getShow('personalTransAmount')"
              prop="personalTransAmount"
              label="代理内转"
      />
      <el-table-column
              v-if="getShow('personalCommission')"
              prop="personalCommission"
              label="返佣"
      />
      <el-table-column
              v-if="getShow('personalDepositAmount')"
              label="总入金">
        <template slot-scope="scope">
          <div>{{scope.row.personalDepositAmount}}</div>
        </template>
      </el-table-column>
      <el-table-column
              v-if="getShow('personalOutAmount')"
              prop="storage"
              label="总出金"
      >
        <template slot-scope="scope">
          <div>{{scope.row.personalOutAmount}}</div>
        </template>
      </el-table-column>
      <el-table-column
              v-if="getShow('personalNetIncome')"
              prop="personalNetIncome"
              label="净入金"
      >
        <template slot-scope="scope">
          <div>{{scope.row.personalNetIncome}}</div>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
  </div>
</template>

<script>
  import { getDict } from '@/api/system/dict'
  import { export_json_to_excel,formatJson } from '@/vendor/Export2Excel'
  import { queryReportTotal } from '@/api/ib/tManager'
  import checkPermission from '@/utils/permission'
  import initDataComm from '@/mixins/initData'
  import { initData } from '@/api/data'
  import { parseTime } from '@/utils/index'
  import eHeader from '@/components/stat/performance/header'

  export default {
    name: 'performance',
    components: { eHeader },
    mixins: [initDataComm],
    props: {},
    data() {
      return {
        loading: false,
        statisticsInterface:{
          sumAgent: '', //
          sumCommission: "",
          sumCustomer: '',
          sumDepositAmount: "",
          sumNetIncome: "",
          sumOutAmount: "",
          sumProfit: "",
          sumTransAmount: "",
          sumVolume: "",
          sumEquity: "",
          sumCredit: "",
          sumBalance: "",
        },
        colList: [
          {
            label: 'managerId',
            text: '销售ID'
          },
          {
            label: 'managerName',
            text: '销售姓名'
          },
          {
            label: 'signDate',
            text: '入职时间'
          },
          {
            label: 'parentManagerId',
            text: '上级ID'
          },
          {
            label: 'parentManagerName',
            text: '上级姓名'
          },
          {
            label: 'personalCustomer',
            text: '客户数'
          },
          {
            label: 'personalAgent',
            text: '代理数'
          },
          {
            label: 'equity',
            text: '总净值'
          },
          {
            label: 'credit',
            text: '总信用额'
          },
          {
            label: 'balance',
            text: '总余额'
          },
          {
            label: 'personalProfit',
            text: '总盈亏'
          },
          {
            label: 'personalVolume',
            text: '交易手数'
          },
          {
            label: 'personalTransAmount',
            text: '代理内转'
          },
          {
            label: 'personalCommission',
            text: '返佣'
          },
          {
            label: 'personalDepositAmount',
            text: '总入金'
          },
          {
            label: 'personalOutAmount',
            text: '总出金'
          },
          {
            label: 'personalNetIncome',
            text: '净入金'
          },
        ],
        checkList: ['managerId','managerName','signDate','parentManagerId','parentManagerName','personalCustomer','personalAgent','equity','credit','balance','volume','personalProfit','personalVolume','personalTransAmount','personalCommission','personalDepositAmount','personalOutAmount','personalNetIncome'],
        allCheckList: ['managerId','managerName','signDate','parentManagerId','parentManagerName','personalCustomer','personalAgent','equity','credit','balance','volume','personalProfit','personalVolume','personalTransAmount','personalCommission','personalDepositAmount','personalOutAmount','personalNetIncome'],

        positionList: [],
      }
    },
    created() {
      this.getPositionList();
      this.$nextTick(() => {
        this.getTotol();
        this.init();
      })
    },
    updated(){
      this.$nextTick(() => {
        this.setSumProfitColor();
      })
    },
    methods: {
      getPosition(row){
        for (let i = 0; i < this.positionList.length; i++) {
          if(row.position == this.positionList[i].value){
            return this.positionList[i].label;
          }
        }
      },
      parseTime,
      checkPermission,
      getPositionList(){
        getDict({
          type: 'managerPosition'
        }).then((res) => {
          console.log(res);
          this.positionList = res.content;
        });
      },
      getShow(str){
        return this.checkList.includes(str);
      },
      sendCheckHandle(list){
        this.checkList = list;
      },
      getTotol(){
        let params = {
          peopleType: 1,
        };
        if(this.$refs.header.startTime){
          params.startTime = this.$refs.header.startTime;
        }
        if(this.$refs.header.endTime){
          params.endTime = this.$refs.header.endTime;
        }
        if(this.query.name){
          params.managerInfo = this.query.name;
        }
        if(this.query.upName){
          params.parentInfo = this.query.upName;
        }
        if(this.$refs.header.empStatus){
          params.empStatus = this.$refs.header.empStatus;
        }
        if(this.query.managerInfo){
          params.managerInfo = this.query.managerInfo;
          if(this.query.dataType){
            params.dataType = this.query.dataType;
          }else {
            params.dataType = 0;
          }
        }
        if(this.query.position){
          params.position = this.query.position;
        }
        queryReportTotal(params).then((res) => {
          this.statisticsInterface = res.statistics[0];
        });
      },
      setSumProfitColor(){
        const s_table = document.getElementsByClassName('el-table__footer-wrapper')[0]
        const child_tr_childivs = s_table.getElementsByTagName('tr')[0].childNodes;
        child_tr_childivs.forEach((item,index) => {
          if(index == 9){
            let divEle = item.getElementsByClassName('cell')[0];
            if(divEle.innerText.indexOf('-') > -1){
              divEle.setAttribute('style', 'color: #E74B3A');
            }else {
              divEle.setAttribute('style', 'color: #11AC80');
            }
          }
        })
      },
      beforeInit() {
        this.url = '/crm/managerAchievement';
        this.params = {
          page: this.page,
          size: this.size,
          peopleType: 1
        }
        if (this.query.name != null && this.query.name !== '') {
          this.params.managerInfo = this.query.name;
        }

        if (this.query.upName != null && this.query.upName !== '') {
          this.params.parentInfo = this.query.upName;
        }
        if (this.$refs.header.startTime != null) {
          this.params['startTime'] = this.$refs.header.startTime;
        }
        if (this.$refs.header.endTime != null) {
          this.params['endTime'] = this.$refs.header.endTime;
        }
        if (this.$refs.header.empStatus != null && this.$refs.header.empStatus != '') {
          this.params['empStatus'] = this.$refs.header.empStatus;
        }
        if(this.query.managerInfo){
          this.params.managerInfo = this.query.managerInfo;
          if(this.query.dataType){
            this.params.dataType = this.query.dataType;
          }else {
            this.params.dataType = 0;
          }
        }
        if(this.query.position){
          this.params.position = this.query.position;
        }
        return true;
      },
      getSummaries(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          switch (column.label) {
            case '销售ID':
              sums[index] = '合计';
              break;
            case '客户数':
              sums[index] = this.statisticsInterface.sumCustomer;
              break;
            case '代理数':
              sums[index] = this.statisticsInterface.sumAgent;
              break;
            case '总净值':
              sums[index] = this.statisticsInterface.sumEquity;
              break;
            case '总信用额':
              sums[index] = this.statisticsInterface.sumCredit;
              break;
            case '总余额':
              sums[index] = this.statisticsInterface.sumBalance;
              break;
            case '总盈亏':
              sums[index] = `${this.statisticsInterface.sumProfit}`;
              break;
            case '交易手数':
              sums[index] = `${this.statisticsInterface.sumVolume}手`;
              break;
            case '代理内转':
              sums[index] = this.statisticsInterface.sumTransAmount;
              break;
            case '返佣':
              sums[index] = this.statisticsInterface.sumCommission;
              break;
            case '总入金':
              sums[index] = this.statisticsInterface.sumDepositAmount;
              break;
            case '总出金':
              sums[index] = this.statisticsInterface.sumOutAmount;
              break;
            case '净入金':
              sums[index] = this.statisticsInterface.sumNetIncome;
              break;
          }
        });

        return sums;
      },
      getGreen(row){
        if(row.personalProfit.indexOf('+') > -1){
          return true;
        }else {
          return false;
        }
      },
      clearHandle(){
        this.query = {
          managerInfo: '',
          dataType: '',
        }
        this.toQuery();
      },
      exportHandle(){
        let params = {
          peopleType: 1,
          export: 1,
        }
        if (this.query.name != null && this.query.name !== '') {
          params.managerInfo = this.query.name;
        }

        if (this.query.upName != null && this.query.upName !== '') {
          params.parentInfo = this.query.upName;
        }
        if (this.$refs.header.startTime != null) {
          params['startTime'] = this.$refs.header.startTime;
        }
        if (this.$refs.header.endTime != null) {
          params['endTime'] = this.$refs.header.endTime;
        }
        if (this.$refs.header.empStatus != null && this.$refs.header.empStatus != '') {
          params['empStatus'] = this.$refs.header.empStatus;
        }
        if(this.query.managerInfo){
          params.managerInfo = this.query.managerInfo;
          if(this.query.dataType){
            params.dataType = this.query.dataType;
          }else {
            params.dataType = 0;
          }
        }
        this.$refs['header'].loading = true;
        initData(this.url, params).then(res => {
          this.$refs['header'].loading =false;
          const tHeader =['销售ID','销售姓名','上级ID','上级姓名','客户数', '代理数','总净值','总信用额','总余额','总盈亏','交易手数','代理内转','返佣','总入金','总出金','净入金'];
          const filterVal =['managerId','managerName','parentManagerId','parentManagerName','personalCustomer', 'personalAgent','equity','credit','balance','personalProfit','personalVolume','personalTransAmount','personalCommission','personalDepositAmount','personalOutAmount','personalNetIncome'];
          const exportData = formatJson(filterVal,res.content);
          let fileName='销售报表';
          export_json_to_excel(tHeader,exportData,fileName);
        }).catch(err => {
          this.$refs['header'].loading =false
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .el-table__footer-wrapper {
    .has-gutter {

    }
  }
  .profit-text {
    color: #E74B3A;
    &.on {
      color: #11AC80;
    }
  }
</style>
<style lang="less">
    .deep {
        .el-table {
            display: flex;
            flex-direction: column;
        }
        /* order默认值为0，只需将表格主体order设为1即可移到最后，合计就上移到最上方了 */
        .el-table__body-wrapper {
            order: 1;
        }
    }
</style>
